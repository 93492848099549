
// App.js

import './App.css';
import React, { useEffect, useState } from "react";
import IngredientForm from './IngredientForm';
import QueryIngredientsInfo from './QueryIngredientsInfo';
import RecipesList from './RecipesList'; 
import SearchSettingsButton from './SearchSettingsButton'; 
import SearchSettingsModal from './SearchSettingsModal'; 
import { connect, Provider } from 'react-redux'
import { mapDispatchToProps } from './actions'
import { mapStateToProps } from './store'

function App(props) {
  const [ingredientName, setIngredientName] = useState('');
  const [isSearchSettingsModalOpen, setIsSearchSettingsModalOpen] = useState(false);

  const displayIngredientForm = () => {
    return (
      <IngredientForm
        ingredientName={ingredientName}
        setIngredientName={setIngredientName}
        props={props}
      />
    );
  };

  const displaySearchSettingsButton = () => {
    return <SearchSettingsButton 
      setIsSearchSettingsModalOpen={setIsSearchSettingsModalOpen}
      props={props}
    />
  }


  const displayQueryIngredientsInfo = () => {
    return <QueryIngredientsInfo props={props} />
  }

  
  const displayRecipesList = () => {
    return <RecipesList props={props} />
  };

  const displaySearchSettingsModal = () => {
    return (
      <SearchSettingsModal
        isSearchSettingsModalOpen={isSearchSettingsModalOpen}
        setIsSearchSettingsModalOpen={setIsSearchSettingsModalOpen}
        props={props}
      />
    );
  };

  return (
    <div className="App">
      <h1>What Should I Cook Today?</h1>
      {displayIngredientForm()}
      {displaySearchSettingsButton()}
      <hr />
      {displayQueryIngredientsInfo()}
      <hr />
      <h2>Recipes:</h2>
      {displayRecipesList()}
      {displaySearchSettingsModal()}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

