import React from 'react';

const IngredientForm = ({ ingredientName, setIngredientName, props }) => {
  const handleSubmit = (event) => {
    if (isFormSubmissionEvent(event)) {
      event.preventDefault();  // Prevent form from submitting and reloading page
      if (ingredientName) {
        props.addIngredient(ingredientName,  props.receiveRecipes, props.receiveEmoji);
      }
      setIngredientName('');
    }
  };

  const isFormSubmissionEvent = (event) => {
    if (event.type === 'click') {
      return true;
    }
    if (event.type === 'keydown') {
      return event.key === 'Enter';
    }
    return false;
  }

  const displayIngredientForm = () => {
   return <div id="ingredient-form-container">
        <form id="ingredient-form">
          <h2>
            Enter ingredient name to add to your kitchen: <input
              id="ingredient-input"
              type="text"
              placeholder="e.g. eggs"
              value={ingredientName}
              onChange={(event) => setIngredientName(event.target.value)}
              onKeyDown={handleSubmit}
            />
            <button type="button" id="add-ingredient-button" className="button" onClick={handleSubmit}>Add</button> 
          </h2>
        </form>
      </div>
  }

  return displayIngredientForm();

}

export default IngredientForm;