import { areAnySelected, getSelectedTags } from './choices.js'

const SERVER_URL = "https://reverse-recipe-s-1682472528534.wl.r.appspot.com";
//const SERVER_URL = "http://localhost:8080"
const EMOJI_SERVER_ENDPOINT = SERVER_URL + "/emoji";
const RECIPES_SERVER_ENDPOINT = SERVER_URL + "/recipes";

// ingredientName is a string
// onEmojiReceived is a callback function that takes 2 arguments:
// 1. ingredientName (string)
// 2. emoji (string)
export const callEmojisApi = (ingredientName, onEmojiReceived) => {
  const queryString = 'ingredient=' + ingredientName;
  fetch(`${EMOJI_SERVER_ENDPOINT}?${queryString}`)
    .then(response => response.json())
    .then(responseJSON => onEmojiReceived(ingredientName, responseJSON.emoji))
    .catch(error => console.error("Error calling emoji API: ", error));
}

// queryIngredients is an array of ingredient objects
// queryParams includes settings such as maxRecipeTime
// onRecipesReceived is a callback function that takes 1 argument (a JSON object)
export const callRecipesApi = (queryIngredients, queryParams, onRecipesReceived) => {
  const queryString = encodeQueryString(queryIngredients, queryParams);
  // Make the API call using fetch()
  fetch(`${RECIPES_SERVER_ENDPOINT}?${queryString}`)
    .then(response => response.json())
    .then(responseJSON => onRecipesReceived(responseJSON))
    .catch(error => console.error("Error calling recipes API: ", error));  
}  

const encodeQueryString = (queryIngredients, queryParams) => {
  let queryString = 'ing=' + encodeIngredientsAsString(queryIngredients);
  if (queryParams.maxRecipeTime !== null) {
    queryString += '&mrt=' + queryParams.maxRecipeTime;
  }
  if (areAnySelected(queryParams.cuisines)) {
    queryString += '&cui=' + getSelectedTags(queryParams.cuisines).join(",");
  }
  if (areAnySelected(queryParams.categories)) {
    queryString += '&cat=' + getSelectedTags(queryParams.categories).join(",");
  }
  return queryString;
};

// Example:
// In this example, the pork is user-added. Salt, black pepper, and flour are automatically prepopulated.
// The encoded string would be:
// pork:ua,salt,black pepper,flour
const encodeIngredientsAsString = (queryIngredients) => {
  return queryIngredients.map(getEncodedIngredientString).join(",");
};

const getEncodedIngredientString = (ingredient) => {
  const tag = getTagForIngredient(ingredient);
  if (tag) {
    return ingredient.name + ":" + tag;
  }
  return ingredient.name;
};

const getTagForIngredient = (ingredient) => {
  let tag = '';
  if (ingredient.isUserAdded) {
    tag += 'u';
  }  
  if (ingredient.isPreferred) {
    tag += 'p';
  }
  return tag;
};