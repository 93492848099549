import React from 'react';
import { areAnySelected, getSelectedNames } from './choices';

const SearchSettingsButton = ({ setIsSearchSettingsModalOpen, props }) => {
  const displaySearchSettingsButton = () => {
    return <div className="search-settings-button"
                onClick={() => setIsSearchSettingsModalOpen(true)}>
               ⭐ Advanced Search Settings
               {summarizeSearchSettings()}
           </div>
  }

  const summarizeSearchSettings  = () => {
    const queryParams = props.queryParams;
    const summaryStrings  = [];
    if (queryParams.maxRecipeTime != null) {
      summaryStrings.push('at most ' + queryParams.maxRecipeTime + ' mins');
    }
    if (areAnySelected(queryParams.cuisines)) {
      summaryStrings.push(getSelectedNames(queryParams.cuisines).join(', '));
    }
    if (areAnySelected(queryParams.categories)) {
      summaryStrings.push(getSelectedNames(queryParams.categories).join(', '));
    }
    if (!summaryStrings.length) {
      return '';
    }
    return ' -- ' + summaryStrings.join(' | ');
  }

  return displaySearchSettingsButton();
}

export default SearchSettingsButton;