import React from 'react';
import Modal from 'react-modal';
import QueryIngredientsInfo from './QueryIngredientsInfo';
import { areAnySelected } from './choices.js'

const SearchSettingsModal = ({ isSearchSettingsModalOpen, setIsSearchSettingsModalOpen, props }) => {
  const onChangeMaxRecipeTime = (event) => {
    props.setQueryParams(
      { maxRecipeTime: parseInt(event.target.value, 10) || null },
      props.receiveRecipes
    );
  };

  const displayQueryIngredientsInfo = () => {
    return <QueryIngredientsInfo
        props={props}
      />
  }  

  const displayMaxRecipeTimeFilter = () => {
    return <div className="filter">
      Only recipes{' '}
      <input
        id="max-recipe-time-input"
        type="text"
        placeholder="e.g. 30"
        value={props.queryParams.maxRecipeTime || ''}
        onChange={onChangeMaxRecipeTime}
      />{' '}
      minutes or faster
      </div>
  }

  const displayCuisinesFilter = () => {
    return displayChoicesFilter(props.queryParams.cuisines, "Cuisines", props.toggleSelectedCuisine);
  }

  const displayCategoriesFilter = () => {
    return displayChoicesFilter(props.queryParams.categories, "Categories", props.toggleSelectedCategory);
  }

  const displayChoicesFilter = (choicesList, title, toggleFn) => {
    return <div className="filter">
      {title}:{ } 
      <span className="selected-or-all-label">
        { areAnySelected(choicesList) ? "Selected" : "All" }
      </span>
      {
        choicesList.map((choice) => {
          const className = choice.isSelected ? "chip-highlighted" : "chip-deselected";
          const onClick = () => toggleFn(choice.name, !choice.isSelected, props.receiveRecipes);
          return  <li key={choice.name} onClick={onClick} className={className}>{choice.name}</li>
        })
      }
      </div>
  }

  return (
    <Modal
      isOpen={isSearchSettingsModalOpen}
      onRequestClose={() => setIsSearchSettingsModalOpen(false)}
      contentLabel="Search Settings Modal"
    >
      <h1>Search Settings</h1>
      <hr />
      {displayQueryIngredientsInfo()}
      <hr />
      <h2>Filters:</h2>
      {displayMaxRecipeTimeFilter()}
      {displayCuisinesFilter()}
      {displayCategoriesFilter()}
      <hr />
      <button className="button" onClick={() => setIsSearchSettingsModalOpen(false)}>
        See Recipes
      </button>
    </Modal>
  );
};

export default SearchSettingsModal;