import React from 'react';
import {convertISO8601ToReadable, getHostName } from './string_formatting'
import { areAnySelected } from  './choices'

const RecipesList = ({ props }) => {
  const displayRecipesOrPlaceholder = (recipesList) => {
    console.log("displayRecipesOrPlaceholder", props);
    if (props.isInitialState) {
      return <h3 id="recipes-placeholder">Add or remove ingredients to see recipes. </h3>;
    } 
    if (props.isLoadingRecipes) {
      return <h3 id="recipes-placeholder">Loading recipes...</h3>;
    }
    if (recipesList.length === 0) {
      return <h3 id="recipes-placeholder">No recipes found - try removing some restrictions.</h3>;
    }
    return displayRecipes(recipesList);
  }

  const displayRecipes = (recipesList) => {
    return recipesList.map((recipe) => {
      const { title, sourceUrl, usedIngredients, missedIngredients, imageUrl } = recipe;
      return (
        <li key={recipe.sourceUrl}>
          <a href={sourceUrl} target="_blank">
            <h3>{title}</h3>
          </a>
          <div className="recipe-image-container">
            <img src={imageUrl}/>
            <div className="recipe-hostname-overlay">
              {getHostName(sourceUrl)}
            </div>
          </div> 
          <span className="used-ingredients">{usedIngredients.join(', ')}{missedIngredients.length > 0 && ','}</span>
          {missedIngredients.length > 0 && (
            <span className="missed-ingredients">{missedIngredients.join(', ')}</span>
          )}
          <span className="recipe-details">{getRecipesDetailsString(recipe)}</span>
        </li>
      );
    });
  }

  const getRecipesDetailsString = (recipe) => {
    const { totalTime, recipeCuisine, categories } = recipe;
    const detailsArray = [];
    if (totalTime) {
      detailsArray.push(convertISO8601ToReadable(totalTime))
    }
    if (recipeCuisine && wasCuisineQueried()) {
      detailsArray.push(recipeCuisine.join(', '));
    }
    if (categories && wasCategoryQueried()) {
      detailsArray.push(categories.join(', '));
    }
    // Return string based on detailsArray
    if (!detailsArray.length) {
      return '';
    }
    return detailsArray.join(' | ');
  }

  const  wasCuisineQueried = () =>  {
    return areAnySelected(props.queryParams.cuisines);
  }

  const  wasCategoryQueried = () =>  {
    return areAnySelected(props.queryParams.categories);
  }

  return <ul id="recipe-list">
    {displayRecipesOrPlaceholder(props.recipes)}
  </ul>
}

export default RecipesList;