export const convertISO8601ToReadable = (durationString) => {
  //const durationRegex = /^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
  const durationRegex = /^P(?:(\d+(?:\.\d+)?)Y)?(?:(\d+(?:\.\d+)?)M)?(?:(\d+(?:\.\d+)?)D)?(?:T(?:(\d+(?:\.\d+)?)H)?(?:(\d+(?:\.\d+)?)M)?(?:(\d+(?:\.\d+)?)S)?)?$/;
  const match = durationRegex.exec(durationString);

  if (!match) {
    console.error('Invalid ISO 8601 duration format', durationString);
    return '';
  }

  let [, years, months, days, hours, minutes, seconds] = match;

  // Convert something like "75 minutes" to "1 hour, 15 minutes"
  if (minutes > 60) {
    hours = (hours || 0) + Math.floor(minutes / 60);
    minutes = minutes % 60;
  }

  // Construct string
  let output = '';

  if (years > 0) {
    output += `${years} year${years > 1 ? 's' : ''} `;
  }

  if (months > 0) {
    output += `${months} month${months > 1 ? 's' : ''} `;
  }

  if (days > 0) {
    output += `${days} day${days > 1 ? 's' : ''} `;
  }

  if (hours > 0) {
    output += `${hours} hour${hours > 1 ? 's' : ''} `;
  }

  if (minutes > 0) {
    output += `${minutes} min${minutes > 1 ? 's' : ''} `;
  }

  if (seconds > 0) {
    output += `${seconds} sec${seconds > 1 ? 's' : ''} `;
  }

  return output.trim();
};

export const getHostName = (sourceUrl) => {
  if (sourceUrl.startsWith('//')) {
    sourceUrl = 'http:' + sourceUrl;
  }
  let hostname = '';
  try {
    hostname = (new URL(sourceUrl)).hostname;
  } catch (e) {
    console.error(e, sourceUrl);
    return '';
  }
  if (hostname.startsWith('www.')) {
    hostname = hostname.slice(4);
  }
  return hostname;
}
