import { createStore } from 'redux'
import { actions } from './actions'
import { debugLog } from './debug_logging'
import { callEmojisApi, callRecipesApi } from './api_calls'
import { cuisines } from './cuisines.js'
import { categories } from './categories.js'


// Initialize list of ingredients with pantry staples
const INITIAL_INGREDIENTS_INFO = {
  "salt": "🧂",
  "black pepper": "🧂",
  "flour": "🌾",
  "butter": "🧈",
  "sugar": "🍭",
  "vegetable oil": "🍳",
  "water": "💧"
};

const makeInitialState = () => ({
    currentIngredients: makeInitialIngredients(),
    queryParams: {
      maxRecipeTime: null,
      cuisines,
      categories,
      maxNumIngredients: null
    },
    recipes: [],
    isInitialState: true,
    isLoadingRecipes: false
  });

// Reducer
const recipeReducer = (state = makeInitialState(), action) => {
    console.log("state: ", state);
    switch (action.type) {
      case actions.ADD_INGREDIENT:
      {
        if (containsIngredient(state.currentIngredients, action.ingredientName)) {
          // Ingredient is already in list - bail out.
          return state;
        }
        const updatedIngredients = [makeUserAddedIngredient(action.ingredientName), ...state.currentIngredients];
        debugLog('Added ingredient', action, updatedIngredients);
        callRecipesApi(updatedIngredients, state.queryParams, action.onRecipesReceived);
        callEmojisApi(action.ingredientName, action.onEmojiReceived);
        return { ...state, currentIngredients: updatedIngredients, isLoadingRecipes: true, isInitialState: false};
      }
      case actions.DELETE_INGREDIENT:
      { 
        debugLog('Deleting ingredient', action);
        const ingrToRemove = action.ingredientName;
        const updatedIngredients = state.currentIngredients.filter((ingr) => ingr.name !== ingrToRemove);
        callRecipesApi(updatedIngredients, state.queryParams, action.onRecipesReceived);
        return { ...state, currentIngredients: updatedIngredients, isLoadingRecipes: true, isInitialState: false};
      }
      case actions.TOGGLE_PREFERRED_INGREDIENT:
      {
        debugLog('Toggling preferred ingredient', action);
        // Find the ingredient matching action.ingredientName in currentIngredients; for that ingredient object,
        // overwrite the isPreferred field with action.shouldBePreferred.
        // The rest of the state stays the same.
        const updatedIngredients = state.currentIngredients.map(
          (ingr) => ingr.name === action.ingredientName ? {...ingr, isPreferred: action.shouldBePreferred} : ingr
        );
        callRecipesApi(updatedIngredients, state.queryParams, action.onRecipesReceived);
        return { ...state, currentIngredients: updatedIngredients, isLoadingRecipes: true, isInitialState: false};
      }
      case actions.TOGGLE_SELECTED_CUISINE:
      {
        debugLog('Toggling selected cuisine', action);
        const updatedCuisines = state.queryParams.cuisines.map(
          (cuisine) => cuisine.name === action.cuisineName ? {...cuisine, isSelected: action.shouldBeSelected} : cuisine
        );
        const updatedQueryParams = {...state.queryParams, cuisines: updatedCuisines};
        callRecipesApi(state.currentIngredients, updatedQueryParams, action.onRecipesReceived);
        return { ... state, queryParams: updatedQueryParams, isLoadingRecipes: true, isInitialState: false};
      }
      case actions.TOGGLE_SELECTED_CATEGORY:
      {
        debugLog('Toggling selected category', action);
        const updatedCategories = state.queryParams.categories.map(
          (category) => category.name === action.categoryName ? {...category, isSelected: action.shouldBeSelected} : category
        );
        const updatedQueryParams = {...state.queryParams, categories: updatedCategories};
        callRecipesApi(state.currentIngredients, updatedQueryParams, action.onRecipesReceived);
        return { ... state, queryParams: updatedQueryParams, isLoadingRecipes: true, isInitialState: false};
      }
      case actions.SET_QUERY_PARAMS:
      {
        debugLog('Setting query params', action);
        const updatedQueryParams = Object.assign({}, state.queryParams, action.queryParamsToUpdate);
        callRecipesApi(state.currentIngredients, updatedQueryParams, action.onRecipesReceived);
        return { ... state, queryParams: updatedQueryParams, isLoadingRecipes: true, isInitialState: false};
      }
      case actions.RECEIVE_EMOJI:
      {
        debugLog('Received emoji', action);
        // Find the ingredient matching action.ingredientName in currentIngredients; for that ingredient object,
        // overwrite the emoji field with action.emoji.
        // The rest of the state stays the same.
        const updatedIngredients = state.currentIngredients.map(
          (ingr) => ingr.name === action.ingredientName ? {...ingr, emoji: action.emoji} : ingr
        );
        return { ...state, currentIngredients: updatedIngredients};
      }
      case actions.RECEIVE_RECIPES:
      {
        debugLog('Received recipes', action);
        return { ...state, recipes: action.recipes, isLoadingRecipes: false, isInitialState: false };
      }
      default:
        return state;
    }
}

const makeInitialIngredients = () => {
  const initialIngredients = [];
  // Populate initialIngredients based on INITIAL_INGREDIENTS_INFO
  Object.keys(INITIAL_INGREDIENTS_INFO).forEach((ingredientName) => {
    initialIngredients.push({
      name: ingredientName,
      emoji: INITIAL_INGREDIENTS_INFO[ingredientName],
      isUserAdded: false,
      isPreferred: false
    })
  });
  return initialIngredients;
}

const makeUserAddedIngredient = (ingredientName) => {
  return {
    name: ingredientName,
    emoji: null,  // Will be populated asynchronously with API call
    isUserAdded: true,
    isPreferred: false
  };
}

// ingredientObjects is an array of objects
// ingredientName is a string
// The function returns a boolean indicating whether the ingredient name is found in the list.
const containsIngredient = (ingredientObjects, ingredientName) => {
  for (let i = 0; i < ingredientObjects.length; i++) {
    if (ingredientObjects[i].name === ingredientName) {
      return true;
    }
  }
  return false;
}

// Connect React to Redux
export const mapStateToProps = (state) => {
  // Makes a copy of state
  return Object.assign({}, state);
};


// Store
export default createStore(recipeReducer);
