import { debugLog } from './debug_logging'

// Action types
export const actions = {
	ADD_INGREDIENT: 'ADD_INGREDIENT',
	DELETE_INGREDIENT: 'DELETE_INGREDIENT',
	TOGGLE_PREFERRED_INGREDIENT: 'TOGGLE_PREFERRED_INGREDIENT',
	TOGGLE_SELECTED_CUISINE: 'TOGGLE_SELECTED_CUISINE',
	TOGGLE_SELECTED_CATEGORY: 'TOGGLE_SELECTED_CATEGORY',
	SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',
	RECEIVE_EMOJI: 'RECEIVE_EMOJI',
	RECEIVE_RECIPES: 'RECEIVE_RECIPES'
};

// See api_calls.js for the expected arguments of onRecipesReceived and onEmojiReceived
const addIngredient = (ingredientName, onRecipesReceived, onEmojiReceived) => {
	return {
		type: actions.ADD_INGREDIENT,
		ingredientName,
		onRecipesReceived,
		onEmojiReceived
	};
};

// See api_calls.js for the expected arguments of onRecipesReceived
const deleteIngredient = (ingredientName, onRecipesReceived) => (
	{
		type: actions.DELETE_INGREDIENT,
		ingredientName,
		onRecipesReceived
	});

const togglePreferredIngredient = (ingredientName, shouldBePreferred, onRecipesReceived) => (
	{
		type: actions.TOGGLE_PREFERRED_INGREDIENT,
		ingredientName,
		shouldBePreferred,
		onRecipesReceived
	});

const toggleSelectedCuisine = (cuisineName, shouldBeSelected, onRecipesReceived) => (
	{
		type: actions.TOGGLE_SELECTED_CUISINE,
		cuisineName,
		shouldBeSelected,
		onRecipesReceived
	});

const toggleSelectedCategory = (categoryName, shouldBeSelected, onRecipesReceived) => (
	{
		type: actions.TOGGLE_SELECTED_CATEGORY,
		categoryName,
		shouldBeSelected,
		onRecipesReceived
	});

const setQueryParams = (queryParamsToUpdate, onRecipesReceived) => ({
	type: actions.SET_QUERY_PARAMS,
	queryParamsToUpdate,
	onRecipesReceived
});

const receiveEmoji = (ingredientName, emojiStr) => (
	{
	  type: actions.RECEIVE_EMOJI,
	  ingredientName,
	  emoji: emojiStr 
	});

const receiveRecipes = (recipesList) => ({ type: actions.RECEIVE_RECIPES, recipes: recipesList });

export const mapDispatchToProps = (dispatch) => {
  return {
    addIngredient: (...args) => dispatch(addIngredient(...args)),
    deleteIngredient: (...args) => dispatch(deleteIngredient(...args)),
    togglePreferredIngredient: (...args) => dispatch(togglePreferredIngredient(...args)),
    toggleSelectedCuisine: (...args) => dispatch(toggleSelectedCuisine(...args)),
    toggleSelectedCategory: (...args) => dispatch(toggleSelectedCategory(...args)),
    setQueryParams: (...args) => dispatch(setQueryParams(...args)),
    receiveEmoji: (...args) => dispatch(receiveEmoji(...args)),
    receiveRecipes: (...args) => dispatch(receiveRecipes(...args)),    
  };
};
