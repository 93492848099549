import React from 'react';

const QueryIngredientsInfo = ({ props }) => {
  const displayQueryIngredientsInfo = () => {
    return <>
        <h2>Ingredients in your kitchen:</h2>
        <p className="instructions">Click on an ingredient to emphasize it.</p>
        <ul id="ingredient-list">
          {props.currentIngredients.map((ingredient) => displayIngredient(ingredient))}
        </ul>
      </>
  }

  const displayIngredient = (ingredient) => {
    const onClick = () => {
      props.togglePreferredIngredient(ingredient.name, !ingredient.isPreferred, props.receiveRecipes);
    }
    const className = ingredient.isPreferred ? "chip-highlighted" : "chip-deselected";
    return (
      <li key={ingredient.name} onClick={onClick} className={className}>
        {ingredient.name}
        {ingredient.emoji && displayEmoji(ingredient.emoji)}
        {displayDeleteIcon(ingredient.name)}
      </li>
    );
  }

  const displayEmoji = (emojiStr) => {
    return <span> {emojiStr}</span>;
  }

  const displayDeleteIcon = (ingredientName) => {
    const onClickDelete = () => {
      props.deleteIngredient(ingredientName, props.receiveRecipes);
    }
    return <div className="delete-icon" onClick={onClickDelete}>&times;</div>
  }

  return displayQueryIngredientsInfo();
};

export default QueryIngredientsInfo;