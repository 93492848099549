
/*
==================================
Category Counts:
==================================
Dinner: 5115
Dessert: 3283
Appetizer: 1626
Snack: 1245
Lunch: 1172
Breakfast: 1036
Side Dish: 1030
Drink: 451
main-dish: 233
Entree: 211
dessert: 171
Brunch: 109
appetizer: 68
side-dish: 64
Beverage: 63
Quick and Easy: 63
Condiment: 57
Cocktail: 56
Salad: 55
Soup: 55
Cake: 55
Bread: 55
Ingredient: 51
Pasta: 47
Baking: 38
  */


export const categories = [
    {
      name: "Dinner",
      tags: ["dinner"]
    },
    {
      name: "Dessert",
      tags: ["dessert"]
    },
    {
      name: "Appetizer",
      tags: ["appetizer"]
    },
    {
      name: "Snack",
      tags: ["snack"]
    },
    {
      name: "Lunch",
      tags: ["lunch"]
    },  
    {
      name: "Breakfast",
      tags: ["breakfast"]
    }, 
    {
      name: "Side Dish",
      tags: ["side dish", "side-dish"]
    },    
    {
      name: "Drink / Beverage",
      tags: ["drink", "beverage", "cocktail"]
    }, 
    {
      name: "Main Dish",
      tags: ["main dish", "main-dish"]
    },   
    {
      name: "Quick And Easy",
      tags: ["quick and easy"]
    }, 
    {
      name: "Condiment",
      tags: ["condiment"]
    }     
];