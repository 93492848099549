  /*
==================================
Cuisine Counts:
==================================
American: 1682
U.S.: 448
Italian Inspired: 438
Mexican Inspired: 436
Italian: 305
Southern: 252
Mexican: 211
Asian Inspired: 180
Irish: 91
Chinese: 85
american: 83
French: 81
Korean: 70
Jewish: 67
Canadian: 66
German: 65
Indian: 62
Asian: 60
Japanese: 55
Hawaiian: 54
Greek: 54
Cajun: 53
Spanish: 51
Middle Eastern: 47
Tex Mex: 45
*/

export const cuisines = [
      {
        name: "American / U.S.",
        tags: ["american", "u.s.", "tex mex", "southern", "cajun", "hawaiian"]
      },
      {
        name: "Italian",
        tags: ["italian inspired", "italian"]
      },
      {
        name: "Mexican",
        tags: ["mexican inspired", "mexican", "tex mex"]
      },
      {
        name: "Southern",
        tags: ["southern"]
      },
      {
        name: "Asian",
        tags: ["asian inspired", "asian", "chinese", "korean", "japanese", "indian"]
      },
      {
        name: "Irish",
        tags: ["irish"]
      },
      {
        name: "Chinese",
        tags: ["chinese"]
      },
      {
        name: "French",
        tags: ["french"]
      },
      {
        name: "Korean",
        tags: ["korean"]
      },
      {
        name: "Jewish",
        tags: ["jewish"]
      },
      {
        name: "Canadian",
        tags: ["canadian"]
      },
      {
        name: "German",
        tags: ["german"]
      },
      {
        name: "Indian",
        tags: ["indian"]
      },
      {
        name: "Hawaiian",
        tags: ["hawaiian"]
      },
      {
        name: "Greek",
        tags: ["greek"]
      },
      {
        name: "Cajun",
        tags: ["cajun"]
      },
      {
        name: "Spanish",
        tags: ["spanish"]
      },
      {
        name: "Middle Eastern",
        tags: ["middle eastern"]
      },
      {
        name: "Tex Mex",
        tags: ["tex mex"]
      }
  ];