export const areAnySelected = (choicesList) => {
    for (let i = 0; i < choicesList.length; i++) {
      if (choicesList[i].isSelected) {
        return true;
      }
    }
    return false;
  }

// Input: choicesList is an array of objects
// Returns an array of strings
export const getSelectedNames = (choicesList) => {
  const selectedChoices = choicesList.filter(choice => choice.isSelected);
  return selectedChoices.map(choice => choice.name);
}

// Input: choicesList is an array of objects
// Returns an array of strings
export const getSelectedTags = (choicesList) => {
  const selectedChoices = choicesList.filter(choice => choice.isSelected);
  let tags = [];
  selectedChoices.forEach(
    choice => {
      tags = [...tags, choice.tags];
    });
  return tags;
}

